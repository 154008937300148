import { Inject, Injectable, InjectionToken, Optional } from "@angular/core";
import * as signalR from "@microsoft/signalr";
import { BehaviorSubject, catchError, Observable, throwError } from "rxjs";
import { ChatService } from "./chat-student.service";
import { AuthService } from "app/core/auth/auth.service";

export const CLASSROOM_API_BASE_URL = new InjectionToken<string>('CLASSROOM_API_BASE_URL');

@Injectable({
  providedIn: 'root'
})
export class ChatHubClientService {
  private hubConnection: signalR.HubConnection;

  private messageSubject = new BehaviorSubject<{ userId: string; message: string } | null>(null);
  private userJoinedSubject = new BehaviorSubject<string | null>(null);
  private userDisconnectedSubject = new BehaviorSubject<string | null>(null);

  public message$ = this.messageSubject.asObservable();
  public userJoined$ = this.userJoinedSubject.asObservable();
  public userDisconnected$ = this.userDisconnectedSubject.asObservable();

  constructor(
      private _authService: AuthService,
      private _chatService: ChatService,
      @Optional() @Inject(CLASSROOM_API_BASE_URL) baseUrl?: string
    ) {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(baseUrl + '/chathub', {
        accessTokenFactory: () => this._authService.accessToken
      })
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Information)
      .build();

    this.setupEventListeners();
  }

  public startConnection(): Observable<void> {
    return new Observable<void>((observer) => {
      this.hubConnection
        .start()
        .then(() => {
          observer.next();
          observer.complete();
        })
        .catch((error) => {
          console.error('Erro ao conectar ao SignalR:', error);
          observer.error(error);
        });
    });
  }

  private setupEventListeners(): void {
    this.hubConnection.on('ReceiveMessage', (userId: string, chatId: string) => {
      this._chatService.getChatById(chatId)
        .pipe(
          catchError((error) => {
            console.error(error);
            
            return throwError(error);
          })
        );
    });

    this.hubConnection.on('ErrorReceiveMessage', (userId: string, chatId: string) => {
      this._chatService.getChatById(chatId)
        .pipe(
          catchError((error) => {
            console.error(error);
            
            return throwError(error);
          })
        );
    });

    this.hubConnection.on('UserJoined', (userId: string) => {
      this.userJoinedSubject.next(userId);
    });

    this.hubConnection.on('UserDisconnected', (userId: string) => {
      this.userDisconnectedSubject.next(userId);
    });
  }

  public onChatUpdated(): Observable<{ userId: string; chatId: string }> {
    return new Observable<{ userId: string; chatId: string }>((observer) => {
      const listener = (userId: string, chatId: string) => {
        observer.next({ userId, chatId });
      };
  
      this.hubConnection.on('ReceiveMessage', listener);
  
      return () => {
        this.hubConnection.off('ReceiveMessage', listener);
      };
    });
  }
  

  public joinChat(chatId: string, userId: string): Observable<void> {
    return new Observable<void>((observer) => {
      this.hubConnection
        .invoke('JoinChat', chatId, userId)
        .then(() => {
          observer.next();
          observer.complete();
        })
        .catch((error) => {
          console.error('Erro ao entrar no chat:', error);
          observer.error(error);
        });
    });
  }

  public sendMessage(chatId: string, userId: string, message: string): Observable<void> {
    return new Observable<void>((observer) => {
      this.hubConnection
        .invoke('SendMessage', chatId, userId, message)
        .then(() => {
          observer.next();
          observer.complete();
        })
        .catch((error) => {
          console.error('Erro ao enviar mensagem:', error);
          observer.error(error);
        });
    });
  }

  public stopConnection(): Observable<void> {
    return new Observable<void>((observer) => {
      this.hubConnection
        .stop()
        .then(() => {
          observer.next();
          observer.complete();
        })
        .catch((error) => {
          console.error('Erro ao encerrar conexão:', error);
          observer.error(error);
        });
    });
  }
}
