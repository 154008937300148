import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

  // Redirect empty path to '/example'
  { path: '', pathMatch: 'full', redirectTo: 'start' },

  // Redirect signed-in user to the '/example'
  //
  // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
  // path. Below is another redirection for that path to redirect the user to the desired
  // location. This is a small convenience to keep all main routes together here on this file.
  { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'start' },

  // Auth routes for guests
  {
    path: '',
    canMatch: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty'
    },
    children: [
      { path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule) },
      { path: 'validate-token', loadChildren: () => import('app/modules/auth/login-with-token/login-with-token.module').then(m => m.LoginWithTokenModule) },
      { path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.ResetPasswordModule) },
      { path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule) },

    ]
  },
  
  {
    path: '',
    component: LayoutComponent,
    data: {
      layout: 'empty'
    },
    children: [
      { path: 'waitingroom', loadChildren: () => null },
      { path: 'video', loadChildren: () => null },
    ]
  },
  // Auth routes for authenticated users
  {
    path: '',
    canMatch: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty'
    },
    children: [
      { path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule) },
      { path: 'first-access', loadChildren: () => import('app/modules/first-access/first-access.module').then(m => m.FirstAccessModule) },
      { path: 'terms', loadChildren: () => import('app/modules/terms/terms.module').then(m => m.TermsModule) },
    ]
  },

  // Admin routes
  {
    path: '',
    canMatch: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [
      { path: 'start', loadChildren: () => import('app/modules/admin/start-page/start-page.module').then(m => m.StartPageModule) },
      { path: 'experimental-classes', loadChildren: () => import('app/modules/admin/experimental-classes/experimental-classes.module').then(m => m.ExperimentalClassesModule) },
      { path: 'classes', loadChildren: () => import('app/modules/admin/classes/classes.module').then(m => m.ClassesModule) },
      { path: 'instructors', loadChildren: () => import('app/modules/admin/instructors/instructors.module').then(m => m.InstructorsModule) },
      { path: 'students', loadChildren: () => import('app/modules/admin/students/students.module').then(m => m.StudentsModule) },
      { path: 'documents', loadChildren: () => import('app/modules/admin/documents/documents.module').then(m => m.DocumentsModule) },
      { path: 'users', loadChildren: () => import('app/modules/admin/users/users.module').then(m => m.UsersModule) },
      { path: 'chat', loadChildren: () => import('app/modules/admin/chat-student/chat-student.module').then(m => m.ChatStudentModule) },
    ]
  }
];
