import { Injectable } from '@angular/core';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { Chat } from 'app/modules/admin/chat-student/chat-student.types';
import {
  BehaviorSubject,
  filter,
  map,
  Observable,
  switchMap,
  take,
  throwError,
} from 'rxjs';
import { ChatClient, ChatParticipant } from '../../../../video-api-client';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private _chat: BehaviorSubject<Chat> = new BehaviorSubject(null);
  private _chats: BehaviorSubject<Chat[]> = new BehaviorSubject(null);
  private _chatParticipant: BehaviorSubject<ChatParticipant> = new BehaviorSubject(null);
  private _chatParticipants: BehaviorSubject<ChatParticipant[]> = new BehaviorSubject(null);

  constructor(private _chatClient: ChatClient, private _userService: UserService) { }

  get chat$(): Observable<Chat> {
    return this._chat.asObservable();
  }

  get chats$(): Observable<Chat[]> {
    return this._chats.asObservable();
  }

  get chatParticipant$(): Observable<ChatParticipant> {
    return this._chatParticipant.asObservable();
  }

  get contacts$(): Observable<ChatParticipant[]> {
    return this._chatParticipants.asObservable();
  }

  get currentUser$(): Observable<User> {
    return this._userService.user$
  }

  getChats(): Observable<Chat[]> {
    this._chatClient.listChats().subscribe(chats => {
      this._chats.next(chats)
    })

    return this.chats$
  }

  getChatParticipant(id: string): Observable<ChatParticipant> {
    return this.chats$.pipe(
      filter((chats) => !!chats),
      map((chats) => {
        const participant = chats[0].participant

        if (!participant) {
          throw new Error(`Participante com ID ${id} não encontrado.`);
        }

        return participant;
      })
    );
  }

  getCurrentUser(): Observable<User> {
    return this.currentUser$;
  }

  getChatById(id: string): Observable<any> {
    return this.chats$.pipe(
      map(chats => {
        if (!chats || chats.length === 0) {
          throw new Error('Lista de chats não está carregada ou está vazia');
        }
        return chats.find(chat => chat.id === id);
      }),
      switchMap(find => {
        if (find) {
          return this._chatClient.listMessages(find.id).pipe(
            map(messages => {
              const sortedMessages = messages.sort((a, b) =>
                new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
              );
  
              const processedMessages = sortedMessages.map(message => ({
                chatId: find.id,
                createdAt: message.createdAt,
                isMine: message.isSender,
                content: message.content
              }));
  
              this._chat.next({ ...find, messages: processedMessages });
  
              return this._chat.value;
            })
          );
        } else {
          return throwError(() => new Error('Não encontrado o chat com id ' + id));
        }
      })
    );
  }

  resetChat(): void {
    this._chat.next(null);
  
    this.getChats().pipe(take(1)).subscribe({
      next: (chats) => {},
      error: (err) => {
        console.error('Erro ao recarregar chats:', err);
      },
    });
  }
}
