<div class="bg-white sm:bg-[url('assets/images/backgroud/login-background.png')] sm:bg-cover flex flex-col flex-auto items-center justify-center min-w-0">
    <div class="w-full w-auto p-10 rounded-2xl sm:shadow bg-card">
        <div class="w-full max-w-80 sm:w-80 mx-auto sm:mx-0">
            <!-- Logo -->
            <div class="w-15">
                <img src="assets/images/logo/logo_min.png">
            </div>

            <!-- Title -->
            <div class="mt-8 text-4xl font-bold tracking-tight leading-tight">{{ 'sign_in' | transloco }}</div>
            <div class="flex items-baseline mt-0.5 font-medium">
                <div>{{ 'first_access?' | transloco }}</div>
                <div class="ml-1 text-primary-500 hover:underline cursor-pointer" [routerLink]="['/forgot-password']" [queryParams]="{'first-access': true}">{{ 'click_here' | transloco }}
                </div>
            </div>

            <!-- Alert -->
            <fuse-alert class="mt-8" *ngIf="showAlert" [appearance]="'outline'" [showIcon]="false" [type]="alert.type"
                [@shake]="alert.type === 'error'">
                {{alert.message}}
            </fuse-alert>

            <!-- Sign in form -->
            <form class="mt-8" [formGroup]="signInForm" #signInNgForm="ngForm">

                <!-- Email field -->
                <mat-form-field class="w-full">
                    <mat-label>{{ 'email_or_login' | transloco }}</mat-label>
                    <input id="email" matInput [formControlName]="'email'">
                    <mat-error *ngIf="signInForm.get('email').hasError('required')">
                        {{ 'required_email' | transloco }}
                    </mat-error>
                    <mat-error *ngIf="signInForm.get('email').hasError('email')">
                        {{ 'invalid_email' | transloco }}
                    </mat-error>
                </mat-form-field>

                <!-- Password field -->
                <mat-form-field class="w-full">
                    <mat-label>{{ 'password' | transloco }}</mat-label>
                    <input id="password" matInput type="password" [formControlName]="'password'" #passwordField>
                    <button mat-icon-button type="button"
                        (click)="passwordField.type === 'password' ? passwordField.type = 'text' : passwordField.type = 'password'"
                        matSuffix>
                        <mat-icon class="icon-size-5" *ngIf="passwordField.type === 'password'"
                            [svgIcon]="'heroicons_solid:eye'"></mat-icon>
                        <mat-icon class="icon-size-5" *ngIf="passwordField.type === 'text'"
                            [svgIcon]="'heroicons_solid:eye-off'"></mat-icon>
                    </button>
                    <mat-error>
                        {{ 'required_pass' | transloco }}
                    </mat-error>
                </mat-form-field>

                <!-- Actions -->
                <div class="inline-flex items-center justify-between w-full mt-1.5">

                    <a class="text-md font-medium text-primary-500 hover:underline"
                        [routerLink]="['/forgot-password']">{{ 'forgot_password?' | transloco }}
                    </a>
                </div>

                <!-- Submit button -->
                <button class="fuse-mat-button-large w-full mt-6 rounded-md" mat-flat-button [color]="'primary'"
                    [disabled]="signInForm.disabled" (click)="signIn()">
                    <span *ngIf="!signInForm.disabled">
                        {{ 'start_session' | transloco }}
                    </span>
                    <mat-progress-spinner *ngIf="signInForm.disabled" [diameter]="24"
                        [mode]="'indeterminate'"></mat-progress-spinner>
                </button>
            </form>
        </div>
    </div>
</div>